import { observable, action, computed, toJS, makeObservable } from 'mobx';
import { makeAuthenticatedRequest } from 'utils/API';

type WindowsConfig = {
    id: string;
    allowlist: string;
    protected_processes: string[];
    name: string;
}

export class WindowsConfigStore {
    public loading: boolean;

    private _data: any[];

    constructor() {
        makeObservable(this, {
            // @ts-ignore
            _data: observable,
            loading: observable,

            data: computed,

            onApiFailure: action,
            onLoadDataSuccess: action,

            loadData: action
        });

        this.loading = false;
        this._data = [];
    }

    public get data(): WindowsConfig[] {
        return toJS(this._data);
    }

    onApiFailure = (e: Error) => {
        const errorMessageJSON = JSON.parse(e.message);
        const errorMessageText = errorMessageJSON.detail[0].msg;
        const errorMessageTextUppercase = errorMessageText.charAt(0).toUpperCase() + errorMessageText.slice(1)
        this.loading = false;
        throw errorMessageTextUppercase;
    }

    onLoadDataSuccess = ({ items }: { items: WindowsConfig[], total: number }) => {
        let i = 0;
        while(i<items.length){
            let j = 0;
            while(j < items[i].protected_processes.length){
                let numeral = j + 1; 
                if(items[i].allowlist == undefined){
                    items[i].allowlist = numeral.toString() + ". " + items[i].protected_processes[j] + "  ";
                }else{
                    items[i].allowlist = items[i].allowlist + numeral.toString() + ". " + items[i].protected_processes[j] + "  ";
                }
                j++;
            }
            i++;
        }
        this._data = items;
        this.loading = false;
    }

    loadData = (): Promise<any> => {
        this.loading = true;
        return makeAuthenticatedRequest({
            url: `/api/v1/windows_device_configurations`, 
            options: { method: 'GET' }
        })
            .then(this.onLoadDataSuccess)
            .catch(this.onApiFailure);
    }

    create = (data: WindowsConfig): Promise<any> => {
        this.loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v1/windows_device_configurations`,
            options: { method: 'POST' },
            data
        })
            .then(this.loadData)
            .catch(this.onApiFailure);
    }

    delete = (data: WindowsConfig): Promise<any> => {
        this.loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v1/windows_device_configurations/${data.id}`,
            options: { method: 'DELETE' },
            data
        })
            .then(this.loadData)
            .catch(this.onApiFailure);
    }
}

const STORE = new WindowsConfigStore();

export default STORE;