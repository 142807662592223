import React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { toastFail, toastSuccess } from 'utils/Toast';
import Card from 'components/Card';
import './Profile.scss';

const FormWrapper = styled('div')`
    padding: 20px;
    input {
        margin-bottom: 20px;
    }
`;

const divStyle = {
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
  };

type ProfileProps = {
    UserProfileStore?: any;
    AuthStore?: any;
};

class Profile extends React.Component<ProfileProps, any> {

    componentDidMount() {
        const { AuthStore } = this.props;

        AuthStore.loadProfile()
            .catch((e: Error) => toastFail(e.message));

            AuthStore.loadAPIKey()
            .catch((e: Error) => toastFail(e.message));
    }

    updateProfile = (e: any) => {
        const { UserProfileStore } = this.props;
        const { form } = e.target;
        

        // these will be HTML <input /> elements, not the raw values
        const { first_name, last_name, email } = form;

        e.preventDefault();

        UserProfileStore.updateProfile({
            first_name: first_name.value, 
            last_name: last_name.value, 
            email: email.value,
        })
            .then(() => {
                toastSuccess('Updated profile!');
            })
            .catch(() => {
                toastFail('Error updating user profile.');
            });
    }

    render() {
        const { AuthStore } = this.props;
        const { user } = AuthStore;
        const {
            first_name,
            last_name,
            email
        } = AuthStore.user;

        const logOut = (event: any) => {
            event.preventDefault();
            AuthStore.logout();
        };

        return (
            <div className="Profile">
                <h2>Profile</h2>
                <span className="role">Role: 
                    {AuthStore.isSupervisor ? <span style={{ marginLeft: 6, color: 'white' }}>Supervisor</span> : <span style={{ marginLeft: 6, color: 'white' }}>Admin</span>}
                </span>

                <Card>
                    <div style={divStyle}>
                        { AuthStore.apikey === "" ? 
                            <button 
                                className="update-profile" 
                                type="button"
                                onClick={AuthStore.createAPIToken}
                            >
                                Create API Key
                            </button> : 
                            <form>
                                <FormWrapper>
                                    <label htmlFor="first_name">Personal API Key</label>
                                    <input
                                        name="token"
                                        id="token"
                                        type="text"
                                        placeholder="API token"
                                        className="form-control"
                                        disabled={false}
                                        defaultValue={AuthStore.apikey}
                                    />
                                    <label htmlFor="first_name">User ID</label>
                                    <input
                                        name="userid"
                                        id="userid"
                                        type="text"
                                        placeholder="User ID"
                                        className="form-control"
                                        disabled={false}
                                        defaultValue={AuthStore.user.id}
                                    />
                                </FormWrapper>
                            </form>
                        }
                    </div>
                    <img
                        src={'/img/default-profile.png'}
                        className="img-circle elevation-2"
                        alt="User"
                        style={{height: 100}}
                    />        
                    <p className='first-last-name'>{first_name}&nbsp;{last_name}</p>
                    <form>
                        <FormWrapper>
                            <label htmlFor="first_name">First Name</label>
                            <input
                                name="first_name"
                                id="first_name"
                                type="text"
                                placeholder="Enter your first name"
                                className="form-control"
                                defaultValue={first_name}
                            />

                            <label htmlFor="last_name">Last Name</label>
                            <input
                                name="last_name"
                                id="last_name"
                                type="text"
                                placeholder="Enter your last name"
                                className="form-control"
                                defaultValue={last_name}
                            />

                            <label htmlFor="email" style={{marginRight: 55}}>Email</label>
                            <input
                                name="email"
                                id="email"
                                type="text"
                                placeholder="Enter your email"
                                className="form-control"
                                defaultValue={email}
                            />
                        </FormWrapper>

                        <button 
                        className="update-profile" 
                        type="button"
                        onClick={this.updateProfile}
                        >
                            Update Profile
                        </button>
                        <button 
                            className="logout"
                            onClick={logOut}
                        > 
                        Logout 
                        </button>
                    </form>
                </Card>
            </div>
        );
    }
}

export default inject('UserProfileStore', 'AuthStore')(observer(Profile));