import {KEYS, getSavedValue} from 'utils/LocalStorage';
import AuthStore from 'stores/AuthStore'

const DEFAULT_OPTIONS = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
};

const DOWNLOAD_OPTIONS = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'accept': 'text/csv'
    }
};

type RequestOptions = {
    url: string;
    data?: any;
    options?: any;
    stringifyData?: boolean;
    enforceAuthentication?: boolean;
};

type DownloadRequestOptions = {
    url: string;
    data?: any;
    options?: any;
    stringifyData?: boolean;
    enforceAuthentication?: boolean;
    filename: string;
};


export const makeRequest: ({
                               url,
                               data,
                               options,
                               stringifyData,
                               enforceAuthentication
                           }: RequestOptions) => Promise<any> = ({
                                                                     url,
                                                                     data = undefined,
                                                                     options = {},
                                                                     stringifyData = true,
                                                                     enforceAuthentication = false
                                                                 }: RequestOptions) => {
    const fetchOptions = Object.assign({}, DEFAULT_OPTIONS, options);


    if (data) {
        // primitive values don't need to be stringified
        if (stringifyData && data === Object(data)) {
            fetchOptions.body = JSON.stringify(data);
        } else {
            fetchOptions.body = data;
        }
    }


    return fetch(url, fetchOptions)
        .then(response => {
            if (enforceAuthentication) {
                if (response.status === 401) {
                    return fetch('/api/v1/auth/refresh', Object.assign({}, DEFAULT_OPTIONS,
                        {method: 'POST'}))
                        .then(refresh_response => {
                            if (refresh_response.status === 401) {
                                return AuthStore.logout();
                            } else {
                                if (!refresh_response.ok) {
                                    return refresh_response.text().then(text => {
                                        throw new Error(text);
                                    })
                                } else {
                                    return makeRequest({url, data, options, stringifyData, enforceAuthentication});
                                }
                            }
                        })
                }
            } else {
                if (url == '/api/v1/auth/login') {
                    console.log("checking status for login: " + response.status);
                    if (response.status == 400 || response.status == 422) {
                        return response.text().then(text => {
                            throw new Error(text);
                        })
                    }
                }
            }

            if (response.status >= 400)
                return response.text().then(text => {
                    throw new Error(text);
                })

            return response.json();
        })
        .catch(e => {
            throw e;
        })
}


export const makeAuthenticatedRequest = ({url, data = undefined, options = {}}: RequestOptions) => {
    const fetchOptions = Object.assign({}, DEFAULT_OPTIONS, options);
    //fetchOptions.headers['Authorization'] = `Bearer ${getSavedValue(KEYS.JWT)}`;

    return makeRequest({url, data, options: fetchOptions, enforceAuthentication: true});
}

export const makeDownloadRequest: ({
                                       url,
                                       data,
                                       options,
                                       stringifyData,
                                       enforceAuthentication,
                                       filename
                                   }: DownloadRequestOptions) => Promise<any> = ({
                                                                                     url,
                                                                                     data = undefined,
                                                                                     options = {},
                                                                                     stringifyData = true,
                                                                                     enforceAuthentication = false,
                                                                                     filename
                                                                                 }: DownloadRequestOptions) => {
    const fetchOptions = Object.assign({}, DEFAULT_OPTIONS, options);


    if (data) {
        // primitive values don't need to be stringified
        if (stringifyData && data === Object(data)) {
            fetchOptions.body = JSON.stringify(data);
        } else {
            fetchOptions.body = data;
        }
    }


    return fetch(url, fetchOptions)
        .then(response => {
            if (enforceAuthentication) {
                if (response.status === 401) {
                    return fetch('/api/v1/auth/refresh', Object.assign({}, DEFAULT_OPTIONS,
                        {method: 'POST'}))
                        .then(refresh_response => {
                            if (refresh_response.status === 401) {
                                return AuthStore.logout();
                            } else {
                                if (!refresh_response.ok) {
                                    return refresh_response.text().then(text => {
                                        throw new Error(text);
                                    })
                                } else {
                                    return makeRequest({url, data, options, stringifyData, enforceAuthentication});
                                }
                            }
                        })
                }
            } else {
                if (url == '/api/v1/auth/login') {
                    console.log("checking status for login: " + response.status);
                    if (response.status == 400 || response.status == 422) {
                        return response.text().then(text => {
                            throw new Error(text);
                        })
                    }
                }
            }

            response.text().then(text => {
                console.log(text);
                //const blob = new Blob([text], { "text/csv;charset=utf-8"});
                //const url = window.URL.createObjectURL(blob);
                //const a = document.createElement('a');
                //a.href = url;
                //a.download = 'data.csv';
                //a.click();
                //window.URL.revokeObjectURL(url);
                var modified_blob = new Blob([text], {type: "text/csv"});
                var url = window.URL.createObjectURL(modified_blob);
                var link = document.createElement("a");
                link.download = filename;
                //"alerts.csv";
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            return response;
        })
        .catch(e => {
            throw e;
        })
}

export const makeAuthenticatedDownloadRequest = ({
                                                     url,
                                                     data = undefined,
                                                     options = {},
                                                     filename = ""
                                                 }: DownloadRequestOptions) => {
    const fetchOptions = Object.assign({}, DOWNLOAD_OPTIONS, options);
    //fetchOptions.headers['Authorization'] = `Bearer ${getSavedValue(KEYS.JWT)}`;

    return makeDownloadRequest({url, data, options: fetchOptions, enforceAuthentication: true, filename: filename});
}