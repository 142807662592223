import React from 'react';
import { inject, observer } from 'mobx-react';

import Card from 'components/Card';
import AccountsTable from './AccountsTable';
import EditUserModal from './EditAccountModal';

import './Accounts.scss';
import { toastFail } from 'utils/Toast';
import styled from 'styled-components';
import PROPERTIES from 'properties';

const SHOW_ALL_USERS = 'SHOW_ALL_USERS_CUSTOM_VALUE';

type UsersProps = {
    AccountsStore?: any;
    AuthStore?: any;
};

const { PROTECTION_OPTIONS } = PROPERTIES.ACCOUNTS

class Accounts extends React.Component<UsersProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            account: '',
            role: '',
            id: '',
        };
    }

    componentDidMount() {
        const { AccountsStore,AuthStore } = this.props;
        AccountsStore.getAllAcounts();
        AuthStore.loadMyAccount();
    }
    
    onAddAccount = () => {
        const { AccountsStore } = this.props;
        AccountsStore.setSelectedAccount({});
    }

    onAssignAccountId = (id:any) => {
        this.setState({account: id})
    }

    render() {
        const { AccountsStore, AuthStore } = this.props;
        const { selectedAccount } = AccountsStore;
        const { accounts } = AccountsStore;

        if(AuthStore.isSupervisor){
            AccountsStore.accounts?.items?.forEach((item:any) => {
                item['account_name'] = item.name
            });
        }

        return (
            <div className="Users">
                <h1>Account Administration</h1>

                {AuthStore.parentAccount == "" ? <button 
                    type="button" 
                    className="btn btn-primary new-user" 
                    onClick={this.onAddAccount}
                    style={{ float: 'right', cursor: 'pointer', marginRight: '2rem' }}
                >
                    <i className={'fas fa-plus-circle whiteicolor'} style={{ color: 'white', marginRight: 10 }}></i>
                    Add Account
                </button> : null}

                <span className="total-users">Total Accounts: {accounts.length}</span>
                <AccountsTableContainer>
                    <AccountsTable accounts={accounts} loadingState={AccountsStore.loading}/>
                </AccountsTableContainer>

                {selectedAccount && <EditUserModal />}
                
            </div>
        );
    }
}

const AccountsTableContainer = styled('div')`
    margin: 2rem
`


export default inject('AccountsStore','AuthStore')(observer(Accounts));