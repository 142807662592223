import React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { toastFail, toastSuccess } from 'utils/Toast';
import { User } from 'stores/AuthStore';

import Card from 'components/Card';
import ModalUsers from 'components/ModelUsers';

import './EditUserModal.scss';

const FieldsetColumn = styled('fieldset')`
    input {
        margin-bottom: 20px;
    }
`;

type EditUserModalProps = {
    UsersStore?: any;
    isEditing?: User;
    AuthStore?: any;
};

class EditUserModal extends React.Component<EditUserModalProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            account: '',
            role: '',
        };
    }


    componentDidMount() {
        this.setState({ account: '', role: '' })
        const { UsersStore } = this.props;
        const { selectedUser } = UsersStore;

        UsersStore.getAllAcounts();

        if (selectedUser.id) {
            this.setState({ account: selectedUser.account_id, role: selectedUser.role })
        }
    }

    onSave = (e: any) => {
        const { UsersStore, AuthStore } = this.props;
        const { selectedUser } = UsersStore;
        const { form } = e.target;

        // these will be HTML <input /> elements, not the raw values
        const { first_name, last_name, email, alerts_email, password, account, role } = form;

        e.preventDefault();

        const saveFn = selectedUser.id ? UsersStore.update : UsersStore.create;

        const data:any = {
            id: selectedUser?.id,
            first_name: first_name.value,
            last_name: last_name.value,
            email: email.value,
            alerts_email: alerts_email.value,

            role: role.value,
            password: password.value
        }

        if(account.value) {
            data["account_id"] = account.value;
        }

        saveFn(data)
            .then(() => {
                toastSuccess('User saved!');
                this.onCancel();
            })
            .catch((e:any) => {
                toastFail(e);
            });
    }

    onCancel = () => {
        const { UsersStore } = this.props;
        UsersStore.clearSelectedUser();
        this.setState({ account: '', role: '' })
    }

    onAssignAccountId = (id:any) => {
        this.setState({account: id})
    }

    onAssignRole = (role:any) => {
        this.setState({role: role})
    }
    
    render() {
        const { UsersStore, AuthStore } = this.props;
        const { selectedUser } = UsersStore;
        const { accounts } = UsersStore;

        const {
            first_name,
            last_name,
            email,
            alerts_email
        } = selectedUser || { first_name: '', last_name: '', email: '', alerts_email: '' };

        

        return (
            <ModalUsers title={selectedUser.id ? 'Edit User' : 'Create User'} onClose={this.onCancel}>
                <Card>
                    <form style={{ width: '300px' }}>
                        <FieldsetColumn>
                            <label htmlFor="first_name">First Name</label>
                            <input
                                name="first_name"
                                id="first_name"
                                type="text"
                                placeholder="Johnny"
                                className="form-control"
                                defaultValue={first_name}
                                style={{ backgroundColor: "#343a40", color: 'white', border: 'none' }}
                            />

                            <label htmlFor="last_name">Last Name</label>
                            <input
                                name="last_name"
                                id="last_name"
                                type="text"
                                placeholder="Appleseed"
                                className="form-control"
                                defaultValue={last_name}
                                style={{ backgroundColor: "#343a40", color: 'white', border: 'none' }}
                            />

                            <label htmlFor="email">Email</label>
                            <input
                                name="email"
                                id="email"
                                type="text"
                                placeholder="johnny@appleseedco.io"
                                className="form-control"
                                defaultValue={email}
                                style={{ backgroundColor: "#343a40", color: 'white', border: 'none' }}
                            />

                            <label htmlFor="alerts_email">Email for alerts</label>
                            <input
                                name="alerts_email"
                                id="alerts_email"
                                type="text"
                                placeholder="support@appleseedco.io"
                                className="form-control"
                                defaultValue={alerts_email}
                                style={{ backgroundColor: "#343a40", color: 'white', border: 'none' }}
                            />

                            {AuthStore.isSupervisor ? 
                            <div className='account-selection'>
                                <label>Select an Account</label>
                                <select
                                    name="account"
                                    id="account"
                                    value={this.state.account}
                                    onChange={(e) => this.onAssignAccountId(e.target.value)}
                                    style={{ backgroundColor: "#343a40", color: 'white', border: 'none' }}
                                >
                                    <option disabled value=""> Select an Account </option>
                                    {accounts ? accounts.map((account: any) => <option key={account.id} value={account.id}>{account.name}</option> ) : <p>No Options Available</p>}
                                   
                                </select>
                                <label>Role</label>
                                <select
                                    name="role"
                                    id="role"
                                    value={this.state.role}
                                    onChange={(e) => this.onAssignRole(e.target.value)}
                                    style={{ backgroundColor: "#343a40", color: 'white', border: 'none' }}
                                >
                                    <option disabled value=""> Select a Role </option>
                                    <option value="admin">Admin</option>
                                    <option value="supervisor">Supervisor</option>

                                </select>
                            </div>
                            :  <div className='account-selection'>
                                    <label>Select an Account</label>
                                    <select
                                        name="account"
                                        id="account"
                                        value={this.state.account}
                                        onChange={(e) => this.onAssignAccountId(e.target.value)}
                                        style={{ backgroundColor: "#343a40", color: 'white', border: 'none' }}
                                    >
                                        <option disabled value=""> Select an Account </option>
                                        {accounts ? accounts.map((account: any) => <option key={account.id} value={account.id}>{account.name}</option> ) : <p>No Options Available</p>}
                                    
                                    </select>
                                    <label>Role</label>
                                    <select
                                        name="role"
                                        id="role"
                                        value={this.state.role}
                                        onChange={(e) => this.onAssignRole(e.target.value)}
                                        style={{ backgroundColor: "#343a40", color: 'white', border: 'none' }}
                                    >
                                        <option disabled value=""> Select a Role </option>
                                        <option value="admin">Admin</option>

                                    </select>
                                </div> }

                           


                       
                            

                                <label htmlFor="password">Password</label>
                                <input
                                    name="password"
                                    id="password"
                                    type="password"
                                    className="form-control"
                                    style={{ backgroundColor: "#343a40", color: 'white', border: 'none' }}
                                />
                            

                            
                        </FieldsetColumn>

                        <button className="btn btn-primary" type="button" onClick={this.onSave}>Save</button>
                        <button className="btn btn-secondary" type="button" onClick={this.onCancel}>Close</button>
                    </form>
                </Card>
            </ModalUsers>
        );
    }
}

export default inject('UsersStore', 'AuthStore')(observer(EditUserModal));