import { observable, action, computed, toJS, makeObservable } from 'mobx';


import { makeAuthenticatedRequest } from 'utils/API';
import AuthStore from './AuthStore';

type ProcessInfo = {
    id: string;
    device_id: string;
    process_name: string;
    entropy_score: number;
}

type Process = {
    processesPerUser: ProcessInfo[];
    total:number;
}

class ProcessesPerUserStore {
    public loading: boolean;

    private _data: Process;

    constructor() {
        makeObservable(this, {
            // @ts-ignore
            _data: observable,
            loading: observable,

            data: computed,

            onApiFailure: action,
            onLoadDataSuccess: action,

            loadData: action,
        });

        this.loading = false;
        this._data = { processesPerUser:[],total:0};
    }

    public get data(): Process {
        return toJS(this._data);
    }

    onApiFailure = (e: Error) => {
        this.loading = false;
        throw e;
    }

    onLoadDataSuccess = ({ items, total }: { items: Array<ProcessInfo>, total: number }) => {
        this._data.processesPerUser = items;
        this._data.total = total;
        this.loading = false;
    }

    loadData = () => {
        this.loading = true;

        return makeAuthenticatedRequest({
            // TODO: we don't want user IDs in the URL
            url: `/api/v1/processes`,
            options: { method: 'GET' }
        })
            .then(this.onLoadDataSuccess)
            .catch(this.onApiFailure);
    }

}


const STORE = new ProcessesPerUserStore();

export default STORE;