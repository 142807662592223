import React from 'react';
import { inject, observer } from 'mobx-react';
import DataTable from 'react-data-table-component';

import LoadingComponent from 'utils/LoadingComponent';
import { toastFail, toastSuccess } from 'utils/Toast';


type UsersTableProps = {
    UsersStore?: any;
    loadingState?:any;
    dataTable?: any;
    noDataMessage?: any;
    showAccountName?: boolean;
};

class UsersTable extends React.Component<UsersTableProps, any> {
    onDeleteUser = (selectedUser: any) => {
        const { UsersStore } = this.props;
        if(selectedUser.first_name != "service" && selectedUser.last_name != "user"){
            UsersStore.delete(selectedUser)
            toastSuccess("Deleted user")
        }
        else{
            toastFail("Can't delete service user")
        }
    }

    render() {
        const { UsersStore } = this.props;

        const columns = [
            {
                name: 'First Name',
                selector: (row: any) => row.first_name,
            },
            {
                name: 'Last Name',
                selector: (row: any) => row.last_name,
            },
            {
                name: 'Role',
                selector: (row: any) => row.role,
            },
            {
                name: 'Email',
                selector: (row: any) => row.email,
            },
            {
                name: 'Alerts Email',
                selector: (row: any) => row.alerts_email,
            },
            {
                name: '',
                selector: (row: any) => (
                    <>
                        <i
                            className="fas fa-edit"
                            onClick={() => UsersStore.setSelectedUser(row)}
                            style={{ cursor: 'pointer', padding: 10 }}
                        />

                        <i
                            className="fas fa-trash-alt"
                            onClick={() => {this.onDeleteUser(row)}}
                            style={{ cursor: 'pointer', padding: 10 }}
                        />
                    </>
                )
            },
        ];
        
        if(this.props.showAccountName){
            columns.splice(-1,0,{ 
                name: 'Account',
                selector: (row: any) => row.account_name,
            })
        }

        return (
            <DataTable columns={columns} data={this.props.dataTable} pagination style={{ borderRadius: '30px' }} 
                noDataComponent={
                    <div style={{ backgroundColor: '#232328', color: '#ffffff', width: '100%', padding: '1.5rem', textAlign: 'center' }}>
                        {this.props.loadingState && this.props.loadingState === true ?
                            <LoadingComponent />
                            :

                            <p> {this.props.dataTable ? 'There are no records to display' : this.props.noDataMessage }</p>
                        }
                    </div>
                }/>
        );
    }
}

export default inject('UsersStore')(observer(UsersTable));